import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import serviceCaseScreenTabs from '../../service-cases/serviceCaseScreenTabs';
import config from '../../config';
import modalIds from '../../modalIds';

/**
 * Filter case logs
 */
const loadFilterCaseLogs = function* filterCaseLogs({payload}) {
    const {location, match} = payload;
    const {serviceCaseId} = match.params;
    const {query, search} = location;

    if (query.activeTab !== serviceCaseScreenTabs.PROTOCOL) return;

    // do not reload case history when agent opens send message modal or create note modal or error message modal
    if (location && location.state?.isModalOpen
        && [modalIds.SEND_MESSAGE, modalIds.CREATE_CASE_LOG_NOTE, modalIds.ERROR_MESSAGE_MODAL]
            .includes(location.state?.modalId)
    ) return;

    yield put({
        type: caseLogActionTypes.CLEAR_CASE_LOG_FILTER_RECORDS,
        payload: {},
    });

    const searchQueryParams = new URLSearchParams(search);
    searchQueryParams.append('size', `${config.PROTOCOL_DEFAULT_PAGE_SIZE}`);
    if (!searchQueryParams.get('page')) searchQueryParams.append('page', 0);
    if (!searchQueryParams.get('channel')) searchQueryParams.append('channel', config.CASE_LOG_CHANNEL);

    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    yield fork(
        fetchRequest,
        caseLogActionTypes.FILTER_CASE_LOGS_REQUEST,
        ecsHistoryManagementService.filterCaseLogs,
        {
            searchQueryParams,
            serviceCaseId,
        },
    );

    const filterCaseLogsResponseAction = yield take([
        caseLogActionTypes.FILTER_CASE_LOGS_REQUEST_SUCCEEDED,
        caseLogActionTypes.FILTER_CASE_LOGS_REQUEST_FAILED,
    ]);

    // NOTE: errors are handled implicitly as part of the fetchRequest
    // below you can handle something in addition if needed

    if (!filterCaseLogsResponseAction.error) {
        const {response} = filterCaseLogsResponseAction.payload;
        const {
            caseLogFilterResults,
            totalCount: caseLogFilterResultsCount,
            pageNumber,
        } = response;

        yield put({
            type: serviceCaseActionTypes.STORE_CASE_LOG_FILTER_RESULTS,
            payload: {serviceCaseId, caseLogFilterResults, caseLogFilterResultsCount, pageNumber},
        });
    }
};

export default loadFilterCaseLogs;

import {take, all, call, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savaActionTypes from '../savaActionTypes';
import createServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/createServiceAssignmentQMFeedbackDraft';
import deleteServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/deleteServiceAssignmentQMFeedbackDraft';

/**
 * Select SAVA contract partner flow
 */
const selectSAVAContractPartnerFlow = function* selectSAVAContractPartnerFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(savaActionTypes.SUBMIT_SAVA_CONTRACT_PARTNER_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;
        const {acePartner: selectedContractPartner} = serviceAssignmentData;
        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const {contractPartners} = yield select(state => state.contractPartners);
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        const serviceAssignment = serviceAssignments[serviceAssignmentId];
        if (!selectedContractPartner) continue;

        const previousPartners = serviceAssignment.listOfPreviousPartners || [];
        const firstAvailableCP = serviceAssignment && serviceAssignment.contractPartnerRecommendations
            .find(contractPartnerRecommendation => {
                return !previousPartners.includes(contractPartnerRecommendation.id);
            });

        /* eslint-disable max-len */
        const isVBAPartnerSelected = contractPartners && selectedContractPartner?.id
        && contractPartners[selectedContractPartner.id]?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY;
        /* eslint-enable max-len */
        let deleteQMFeedbackDraft = !isVBAPartnerSelected && ((selectedContractPartner.id === firstAvailableCP.id
                && !serviceAssignment.recommendedContractPartnerId)
            || (serviceAssignment.recommendedContractPartnerId
                && selectedContractPartner.id === serviceAssignment.recommendedContractPartnerId));


        let qmFeedbackDraftData = null;
        const isVBAListWithoutRecommendedContractPartner = isVBAPartnerSelected
            && !serviceAssignment?.qmFeedbackDraft
            && !serviceAssignment?.recommendedContractPartnerId
            && serviceAssignment?.acePartner?.id;

        if ((serviceAssignment.recommendedContractPartnerId
            && !(previousPartners?.length && previousPartners.includes(serviceAssignment.recommendedContractPartnerId))
            && selectedContractPartner.id !== serviceAssignment.recommendedContractPartnerId
            // skip QM flow if VBA partners are in the list
            /* eslint-disable-next-line max-len */
            && (contractPartners || {})[selectedContractPartner.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
            && (serviceAssignment.acePartner
                && serviceAssignment.acePartner.id === serviceAssignment.recommendedContractPartnerId)
        ) || (previousPartners.length > 0
            && firstAvailableCP.id !== selectedContractPartner.id
            // skip QM flow if VBA partners are in the list
            /* eslint-disable-next-line max-len */
            && (contractPartners || {})[selectedContractPartner.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
            && !previousPartners.includes(serviceAssignment.recommendedContractPartnerId)
        ) || (
            // if there was a recommended active CP selected and the agent switches to the VBA list
            // if there is no recommended VBA partner in the list and agent selects other VBA partner
            // then we should open a modal and create a QM draft for the previously selected active CP
            // NOTE: if there was a non-recommended active CP selected, the condition will fail since
            // the QM draft is already created
            isVBAListWithoutRecommendedContractPartner)
        ) {
            yield* openModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: serviceAssignment.acePartner.id});
            const qmNotesConfirmAction = yield take([
                savaActionTypes.CONFIRM_SAVA_QM_NOTES,
                savaActionTypes.DECLINE_SAVA_QM_NOTES,
            ]);

            if (qmNotesConfirmAction.type === savaActionTypes.DECLINE_SAVA_QM_NOTES) {
                yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
                continue;
            }
            if (qmNotesConfirmAction.type === savaActionTypes.CONFIRM_SAVA_QM_NOTES) {
                const {qmDraftData} = qmNotesConfirmAction.payload;
                deleteQMFeedbackDraft = !!serviceAssignment?.qmFeedbackDraft;
                qmFeedbackDraftData = {
                    ...qmDraftData,
                    // if it's a VBA list without the recommended CP, we have to create a QM note
                    // with the list of active contract partners instead of using the VBA partner list
                    ...(!!isVBAListWithoutRecommendedContractPartner && {
                        /* eslint-disable-next-line max-len */
                        contractPartnerRecommendations: serviceAssignment?.activeContractPartnerRecommendationsSnapshot || [],
                    }),
                };
                yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
            }
        }

        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');
        const savaContractPartnerLocationsLayer = yield call(arcGISMap.getLayer, 'sava-contract-partner-locations');
        const savaContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'sava-contract-partner-service-areas');
        const savaContractPartnerRoutesLayer = yield call(arcGISMap.getLayer, 'sava-contract-partner-routes');

        if (savaContractPartnerLocationsLayer
            && savaContractPartnerServiceAreasLayer
            && savaContractPartnerRoutesLayer) {
            yield all([
                call(savaContractPartnerLocationsLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartner.id}'`,
                }),
                call(savaContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartner.id}'`,
                }),
                call(savaContractPartnerRoutesLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartner.id}'`,
                }),
            ]);
        }

        yield* updateServiceAssignment({
            caller: savaActionTypes.SUBMIT_SAVA_CONTRACT_PARTNER_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...selectedContractPartner,
                    ...(selectedContractPartner.businessContactDetails
                        ? {
                            businessContactDetails: {
                                ...selectedContractPartner.businessContactDetails,
                                phoneNo: selectedContractPartner.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                            },
                            contactDetails: null,
                        } : {}
                    ),
                },
                assignmentText: null,
            },
        });

        if (deleteQMFeedbackDraft && serviceAssignment?.qmFeedbackDraft) {
            yield call(deleteServiceAssignmentQMFeedbackDraft, {
                serviceAssignmentLineNo,
                serviceCaseId,
                qmFeedbackDraftId: serviceAssignment.qmFeedbackDraft?.id || null,
            });
        }
        if (qmFeedbackDraftData) {
            yield call(createServiceAssignmentQMFeedbackDraft, {
                qmFeedbackDraftData,
            });
        }
    }
};

export default selectSAVAContractPartnerFlow;

/**
 * App config
 */
export default {
    // General
    APP_VERSION: process.env.APP_VERSION,
    APP_BUILD: process.env.APP_BUILD,
    DEPLOYMENT_ENV: process.env.DEPLOYMENT_ENV,
    IS_FALLBACK_SYSTEM: process.env.IS_FALLBACK_SYSTEM === 'true',
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    BACKDOOR_URL: process.env.BACKDOOR_URL,
    LOCALE: process.env.LOCALE || 'en',
    CURRENCY: process.env.CURRENCY || 'EUR',
    ACE_COMMISSIONER_ID: 491,
    DAMAGE_LOCATION_GERMANY: 'Germany',
    DAMAGE_LOCATION_GERMANY_COUNTRY_CODE: 'DEU',
    CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE: 'DE',
    ARCGIS_DEFAULT_LANG_CODE: 'DE',
    DEFAULT_PAGE_SIZE: 30,
    DEFAULT_DOCUMENTS_TAB_PAGE_SIZE: 20,
    TASKS_DEFAULT_PAGE_SIZE: 20,
    PROTOCOL_DEFAULT_PAGE_SIZE: 500,
    DEFAULT_RENTAL_CAR_DELIVERY_BUDGET: 50,
    MINIMUM_SEARCH_QUERY_LENGTH: 3,
    MINIMUM_CP_SEARCH_QUERY_LENGTH: 2,
    WARNING_DUNNING_LEVEL: 3,
    ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER: 450,
    ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT: 10,
    MAXIMUM_NUMBER_OF_SELECTED_ROOMS: 9,
    MAXIMUM_TEXT_AREA_CONTENT_LENGTH: 250,
    MAXIMUM_LOCATION_NOTES_LENGTH: 255,
    MAXIMUM_SELF_PAYER_NOTE_LENGTH: 255,
    MAXIMUM_DIAGNOSE_DESCRIPTION_LENGTH: 255,
    MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES: 52428800,
    MAXIMUM_SMS_TEXT_AREA_CONTENT_LENGTH: 2500,
    MAXIMUM_QMN_DESCRIPTION_TEXT_AREA_CONTENT_LENGTH: 1000,
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || undefined,
    DATADOG_RUM_APPLICATION_ID: process.env.DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE: process.env.DATADOG_SERVICE,
    DATADOG_ENABLE: process.env.DATADOG_ENABLE === 'true',
    ARCGIS_OAUTH2_SERVICE_URL: process.env.ARCGIS_OAUTH2_SERVICE_URL,
    ARCGIS_OAUTH2_CLIENT_ID: process.env.ARCGIS_OAUTH2_CLIENT_ID,
    ARCGIS_SERVICE_URL: process.env.ARCGIS_SERVICE_URL,
    TEMP_ARCGIS_MAP_ID: process.env.TEMP_ARCGIS_MAP_ID || '',
    ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL || '',
    ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL: process.env.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL || '',
    ARCGIS_RENTAL_CAR_STATIONS_LAYER_URL: process.env.ARCGIS_RENTAL_CAR_STATIONS_LAYER_URL || '',
    GOOGLE_PLACES_API_KEY: process.env.GOOGLE_PLACES_API_KEY || '',
    ACE_LOCALE_TRANSLATION_EN_US_URL: process.env.ACE_LOCALE_TRANSLATION_EN_US_URL,
    ACE_LOCALE_TRANSLATION_DE_DE_URL: process.env.ACE_LOCALE_TRANSLATION_DE_DE_URL,
    ACE_KEYCLOAK_SERVICE_URL: process.env.ACE_KEYCLOAK_SERVICE_URL,
    ACE_KEYCLOAK_REALM: process.env.ACE_KEYCLOAK_REALM,
    ACE_KEYCLOAK_CLIENT_ID: process.env.ACE_KEYCLOAK_CLIENT_ID,
    ACE_MEMBER_PROFILE_AMP_SERVICE_URL: process.env.ACE_MEMBER_PROFILE_AMP_SERVICE_URL || '',
    ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL: process.env.ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL || '',
    ACE_USER_PROFILE_EUP_SERVICE_URL: process.env.ACE_USER_PROFILE_EUP_SERVICE_URL || '',
    ACE_ECS_FLOW_EF_SERVICE_URL: process.env.ACE_ECS_FLOW_EF_SERVICE_URL || '',
    ACE_EF_FILE_ASSETS_SERVICE_ENDPOINT: process.env.ACE_EF_FILE_ASSETS_SERVICE_ENDPOINT || '',
    ACE_EHM_FILE_ASSETS_SERVICE_ENDPOINT: process.env.ACE_EHM_FILE_ASSETS_SERVICE_ENDPOINT || '',
    ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT: process.env.ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT || '',
    ACE_ECS_TASK_MANAGEMENT_ETM_SERVICE_URL: process.env.ACE_ECS_TASK_MANAGEMENT_ETM_SERVICE_URL || '',
    ACE_ECS_HISTORY_MANAGEMENT_EHM_SERVICE_URL: process.env.ACE_ECS_HISTORY_MANAGEMENT_EHM_SERVICE_URL || '',
    ACE_PRICING_MANAGEMENT_APS_SERVICE_URL: process.env.ACE_PRICING_MANAGEMENT_APS_SERVICE_URL || '',
    SILVER_DAT_SERVICE_URL: process.env.SILVER_DAT_SERVICE_URL || '',
    SILVER_DAT_AUTH_SERVICE_URL: process.env.SILVER_DAT_AUTH_SERVICE_URL || '',
    DATAFACTORY_SERVICE_URL: process.env.DATAFACTORY_SERVICE_URL || '',
    DATAFACTORY_AUTH_SERVICE_URL: process.env.DATAFACTORY_AUTH_SERVICE_URL || '',
    TOWING_DESTINATION_COMFORT_TARIFF_RADIUS: 30,
    TOWING_DESTINATION_COMFORT_PLUS_TARIFF_RADIUS: 50,
    GERMANY_CENTER_COORDINATES: [10.451500, 51.165700],
    MEMBER_APP_SERVICE_CASES_PAGE_SIZE: 15,
    ACE_COMMISSIONER: {
        name: 'ACE',
        id: 491,
    },
    CASE_LOG_NOTE_MAX_LENGTH: 2500,
    TASK_DESCRIPTION_MAX_LENGTH: 2500,
    DEFAULT_SERVICE_CASE_LOCK_TIME: 1800, // in seconds (30 mins)
    ASSIGNMENT_IN_STATUS_ASSIGNED_MIN_TIME: 5,
    DOCUMENT_UPLOAD_DESCRIPTION_MAX_LENGTH: 2500,
    MINIMUM_VEHICLE_BUILD_YEAR: 1920,
    MAXIMUM_FILE_ASSETS_IN_VIEW: 5,
    MAX_VEHICLE_WEIGHT: 50000,
    MAX_VEHICLE_HEIGHT: 5,
    MAX_VEHICLE_LENGTH: 25,
    MAX_VEHICLE_WIDTH: 4,
    POST_CODE_FROM: '00000',
    POST_CODE_TO: '99999',
    MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE: 7510,
    GERMAN_NEIGHBORING_COUNTRIES: ['DK', 'PL', 'CZ', 'AT', 'CH', 'FR', 'LU', 'BE', 'NL'],
    MIN_CONTRACT_PARTNER_FIXED_PRICE: 0,
    MAX_CONTRACT_PARTNER_FIXED_PRICE: 999,
    DAMAGE_NOTE_MAX_LENGTH: 1000,
    RECOMMENDED_CONTRACT_PARTNER_RANK: 1,
    INTERSECTION_DATE: '01.01.2025',
    CASE_LOG_CHANNEL: 'ECS',
};

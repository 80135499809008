import {select, take, fork} from 'redux-saga/effects';
import {Address} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import updateServiceCase from './updateServiceCase';

const updateSCMemberResidenceGeolocation = function* updateSCMemberResidenceGeolocation(params) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {serviceCaseId, caseType, snapshotMemberDTO} = params;
    const {personalDetails: personalDetailsDTO} = snapshotMemberDTO;
    const {address: addressDTO} = personalDetailsDTO || {};
    const memberAddress = addressDTO && new Address().fromDTO(addressDTO);

    // skip if no memberAddress or coordinates already set
    if (!memberAddress
        || (personalDetailsDTO
            && personalDetailsDTO.coordinates
            && personalDetailsDTO.coordinates.longitude
            && personalDetailsDTO.coordinates.longitude)) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressLocation,
        {
            ...(memberAddress.street && memberAddress.postCode && memberAddress.city
                ? {
                    address: memberAddress.street,
                    postal: memberAddress.postCode,
                    city: memberAddress.city,
                } : {
                    singleLine: memberAddress.displayAddress,
                    ...(memberAddress.postCode ? {postal: memberAddress.postCode} : {}),
                }),
            forStorage: true,
        },
    );

    const searchMemberAddressGeolocation = yield take([
        serviceCaseActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED,
    ]);
    if (searchMemberAddressGeolocation.error) return;

    const {response} = searchMemberAddressGeolocation.payload;
    const {arcGISLocationCandidateDTOs} = response;

    const arcGISLocationCandidateDTO = memberAddress.postCode
        // if we have postcode in split address field, take first candidate which contains it
        ? (arcGISLocationCandidateDTOs.find(arcGISLocationCandidateDTO => {
            return arcGISLocationCandidateDTO.address?.formattedAddress?.includes(memberAddress.postCode);
        }) || arcGISLocationCandidateDTOs[0])
        // if no postcode in split address field, take first, probably most relevant result
        : arcGISLocationCandidateDTOs[0];
    if (!arcGISLocationCandidateDTO) return;

    yield* updateServiceCase({
        caller: 'SEARCH_MEMBER_ADDRESS_GEOLOCATION',
        caseType,
        serviceCaseId,
        serviceCaseData: {
            member: {
                personalDetails: {
                    address: {
                        countryCode: arcGISLocationCandidateDTO.countryCode,
                    },
                    coordinates: {
                        longitude: arcGISLocationCandidateDTO.longitude,
                        latitude: arcGISLocationCandidateDTO.latitude,
                    },
                },
            },
        },
    });

    // return geolocation
    return arcGISLocationCandidateDTO;
};

export default updateSCMemberResidenceGeolocation;

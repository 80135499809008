import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {efServiceAssignmentStatusTypes, ampMemberTariffAdditionalPackageTypes, efServiceTypes, ampVehicleTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as savaActionTypes from './savaActionTypes';
import CommissioningContractPartnerPanel from '../service-assignments-vehicle/ui-elements/CommissioningContractPartnerPanel';
import CommissioningServiceAndDamageDescription from '../service-assignments-vehicle/ui-elements/CommissioningServiceAndDamageDescription';
import TowingDestinationInfoPanel from '../service-assignments-vehicle/ui-elements/TowingDestinationInfoPanel';
import CommissioningMemberDetailsPanel from '../service-assignments-vehicle/ui-elements/CommissioningMemberDetailsPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CommissioningPeopleOnSitePanel from './ui-elements/CommissioningPeopleOnSitePanel';
import ServiceLocationMapInfoPanel from './ui-elements/ServiceLocationMapInfoPanel';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import config from '../config';
import VehicleInfoPanel from '../service-cases/ui-elements/VehicleInfoPanel';
import TrailerInfoPanel from '../service-assignments/ui-elements/TrailerInfoPanel';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SAVACommissioningTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, users} = props;
    const {submitSAVAStatusChangeForm, submitSAVACommissioningForm, submitCreateSAVAPDF} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const {member} = serviceCase;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [totalCost, setTotalCost] = useState(serviceAssignment?.totalCost || '');

    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);
    const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);
    const hasServiceType = serviceAssignment.serviceType && serviceAssignment.serviceType !== efServiceTypes.NO_SERVICE;
    const {tariffDetails} = member;

    const memberTariff = tariffDetails?.additionalPackages?.includes(ampMemberTariffAdditionalPackageTypes.CAMPER)
    && serviceCase.vehicle?.type === ampVehicleTypes.CAMPER
        ? ampMemberTariffAdditionalPackageTypes.CAMPER
        : tariffDetails?.tariffGroup || '';

    const serviceTypeTranslationKey = serviceAssignment.serviceType.toLowerCase();
    let assignmentDefaultText;
    let assignmentCostCoverageText;
    let assignmentHintText;
    let nonAceAssignmentText;
    let aceAssignmentText;

    const formatAssignmentText = (assignmentDefaultText, assignmentCostCoverageText, assignmentHintText) => {
        return assignmentDefaultText
            + (assignmentCostCoverageText ? '\n\n' + assignmentCostCoverageText : '')
            + (assignmentHintText ? '\n\n' + assignmentHintText : '');
    };

    if (serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}`) {
        nonAceAssignmentText = isDACH && hasServiceType ? translate(`global.assignment_text_non_ace.${serviceTypeTranslationKey}`) : '';
    } else {
        if (isDACH) {
            assignmentDefaultText = translate(`global.default_assignment_text.${serviceTypeTranslationKey}`);
            assignmentCostCoverageText = hasServiceType
                && memberTariff
                && memberTariff !== ampMemberTariffGroups.NON_MEMBER ? (
                    serviceAssignment.selfPayerOption
                        ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_self_payer`)
                        : translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_${memberTariff.toLowerCase()}`)
                ) : '';
            assignmentHintText = translate(`global.general_hint_assignment_text.${serviceTypeTranslationKey}`)
                + (serviceAssignment.selfPayerOption
                    ? translate(`global.self_payer_hint_assignment_text.${serviceTypeTranslationKey}`)
                    : translate(`global.no_self_payer_hint_assignment_text.${serviceTypeTranslationKey}`));
        } else {
            assignmentDefaultText = translate(`global.default_assignment_text.${serviceTypeTranslationKey}`, {}, 'en-US');
            assignmentCostCoverageText = hasServiceType
                && memberTariff
                && memberTariff !== ampMemberTariffGroups.NON_MEMBER
                ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_${memberTariff.toLowerCase()}`, {}, 'en-US')
                : '';
            assignmentHintText = translate(`global.general_hint_assignment_text.${serviceTypeTranslationKey}`, {}, 'en-US')
                + translate(`global.no_self_payer_hint_assignment_text.${serviceTypeTranslationKey}`, {}, 'en-US');
        }
        aceAssignmentText = formatAssignmentText(assignmentDefaultText, assignmentCostCoverageText, assignmentHintText);
    }

    const [assignmentText, setAssignmentText] = useState(
        serviceAssignment.assignmentText
        || aceAssignmentText
        || nonAceAssignmentText,
    );

    useEffect(() => {
        if (!serviceAssignment?.totalCost) return;
        setTotalCost(serviceAssignment.totalCost);
    }, [serviceAssignment.totalCost]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    const onChangeHandler = (key, value) => {
        if (key === 'hasSelfPayerOption') {
            assignmentHintText = translate(`global.general_hint_assignment_text.${serviceTypeTranslationKey}`);

            if (value) {
                assignmentHintText += translate(`global.self_payer_hint_assignment_text.${serviceTypeTranslationKey}`);
                assignmentCostCoverageText = translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_self_payer`);
                setSelfPayer({
                    ...selfPayer,
                    [key]: value,
                });
            } else {
                assignmentHintText += translate(`global.no_self_payer_hint_assignment_text.${serviceTypeTranslationKey}`);
                assignmentCostCoverageText = hasServiceType
                    && memberTariff
                    && memberTariff !== ampMemberTariffGroups.NON_MEMBER
                    ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_${memberTariff.toLowerCase()}`)
                    : '';
                setSelfPayer({
                    ...selfPayer,
                    [key]: value,
                    selfPayerNote: '',
                });
            }

            if (serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}` || !isDACH) return;

            setAssignmentText(formatAssignmentText(
                assignmentDefaultText,
                assignmentCostCoverageText,
                assignmentHintText,
            ));
            return;
        }

        if (key === 'selfPayerNote' && value.length > config.MAXIMUM_SELF_PAYER_NOTE_LENGTH) return;

        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'totalCost') {
            setTotalCost(value);
            return;
        }

        setSelfPayer({
            ...selfPayer,
            [key]: value,
        });
    };

    useEffect(() => {
        setCallbackPhoneNo(serviceAssignment.callbackPhoneNo || '');
    }, [serviceAssignment.callbackPhoneNo]);

    const handleTabChange = useCallback(() => {
        submitSAVACommissioningForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                totalCost,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        assignmentText,
        serviceAssignment.lineNo,
        serviceCase.id,
        submitSAVACommissioningForm,
        selfPayer.hasSelfPayerOption,
        selfPayer.selfPayerNote,
        maximumBudget,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        callbackPhoneNo,
        serviceCase.nonAceMember,
        attachmentsToSendWithAssignment,
        totalCost,
    ]);

    useRouteUnmountEffect(() => {
        handleTabChange();
    }, [handleTabChange]);

    const submitContractPartnerAssignmentChannels = formValues => {
        submitSAVAStatusChangeForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                totalCost,
                acePartner: {
                    assignmentChannels: formValues['assignmentChannelsRequested'] || [],
                    assignmentEmail: formValues['email'],
                    assignmentFaxNo: formValues['faxNo'],
                    assignment2ndEmail: formValues['assignment2ndEmail'],
                    contactDetails: null,
                    rank: serviceAssignment?.acePartner?.rank || null,
                },
            },
            newServiceAssignmentStatus: efServiceAssignmentStatusTypes.ASSIGNED,
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    };
    const submitCreatePDF = formValues => {
        submitCreateSAVAPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                totalCost,
                acePartner: {
                    assignmentChannels: formValues['assignmentChannelsRequested'] || [],
                    assignmentEmail: formValues['email'],
                    assignmentFaxNo: formValues['faxNo'],
                    businessContactDetails: serviceAssignment.acePartner?.businessContactDetails || {},
                    contactDetails: null,
                },
            },
            newServiceAssignmentStatus: efServiceAssignmentStatusTypes.ASSIGNED,
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.serviceType.toLowerCase()}`)
                : serviceAssignment.serviceType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'global!ace-u-margin--top-0',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    maximumBudget={maximumBudget}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                />
                <CommissioningServiceAndDamageDescription
                    assignmentText={assignmentText}
                    totalCost={totalCost}
                    onChangeHandler={onChangeHandler}
                />
                <VehicleInfoPanel />
                {serviceCase.trailer ? (
                    <TrailerInfoPanel />
                ) : null}
                <CommissioningMemberDetailsPanel serviceCase={serviceCase} />
                <CommissioningPeopleOnSitePanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningContractPartnerPanel
                    submitContractPartnerAssignmentChannels={submitContractPartnerAssignmentChannels}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                <ServiceLocationMapInfoPanel serviceLocation={serviceAssignment.serviceLocation} />
                <TowingDestinationInfoPanel />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SAVACommissioningTab.propTypes = {
    submitSAVACommissioningForm: PropTypes.func.isRequired,
    submitSAVAStatusChangeForm: PropTypes.func.isRequired,
    submitCreateSAVAPDF: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SAVACommissioningTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAVACommissioningForm: payload => dispatch({
        type: savaActionTypes.SUBMIT_SAVA_COMMISSIONING_FORM,
        payload,
    }),
    submitSAVAStatusChangeForm: payload => dispatch({
        type: savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE,
        payload,
    }),
    submitCreateSAVAPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVACommissioningTab));
